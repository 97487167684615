// Fonts
@import url('https://fonts.googleapis.com/css?family=Poppins');

// Bootstrap
//@import '~bootstrap/scss/bootstrap';
//@import url('https://ason.it/wp-content/themes/mediclinic/assets/css/modules.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');
@import '~@fortawesome/fontawesome-free/css/all.css';
// OverlayScrollbars
@import '~overlayscrollbars/css/OverlayScrollbars.css';
// iCheck
@import '~icheck-bootstrap/icheck-bootstrap.css';
// AdminLTE
//@import '../../vendor/almasaeed2010/adminlte/dist/css/adminlte.css';
// Variables
@import '~bootstrap/scss/functions';
//@import '../../vendor/almasaeed2010/adminlte/build/scss/bootstrap-variables';
@import 'variables';

@import '../../vendor/almasaeed2010/adminlte/build/scss/variables';
@import '../../vendor/almasaeed2010/adminlte/build/scss/variables-alt';
@import '../../vendor/almasaeed2010/adminlte/build/scss/mixins';

@import '~bootstrap/scss/bootstrap';

@import '../../vendor/almasaeed2010/adminlte/build/scss/parts/core';
@import '../../vendor/almasaeed2010/adminlte/build/scss/parts/components';
@import '../../vendor/almasaeed2010/adminlte/build/scss/parts/extra-components';
@import '../../vendor/almasaeed2010/adminlte/build/scss/parts/pages';
@import '../../vendor/almasaeed2010/adminlte/build/scss/parts/plugins';
@import '../../vendor/almasaeed2010/adminlte/build/scss/parts/miscellaneous';

// Bootstrap
// Already imported by AdminLTE
//@import '~bootstrap/scss/bootstrap';

@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';
//@import '~datatables.net-searchbuilder-bs4/css/searchBuilder.bootstrap4.css';
@import '~datatables.net-buttons-bs4/css/buttons.bootstrap4.css';
@import '~@sweetalert2/theme-bootstrap-4/bootstrap-4';
@import '~select2/dist/css/select2.css';
@import '~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css';
//@import "~summernote/dist/summernote-bs4.css";
@import "~daterangepicker/daterangepicker.css";
@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4.css";
//
//.select2-container--default .select2-selection--multiple .select2-selection__choice {
//    color: #495057 !important;
//}
@import "~toastr/toastr";
@import "~@fullcalendar/common/main.css";

body {
    color: $gray;
    font-family: Poppins, sans-serif;
}

.main-header {
    //background: url(../images/riservata/top_bg.png) center left repeat-x;
    //background-color: $primary;
    //height: 93px;
}

.navbar-light .navbar-nav .nav-link {
    color: $primary;
    font-weight: bold;
}

.brand-image-xs {
    //height: 80px;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    //color: $secondary;
}

.select2-container--bootstrap4 .select2-results__option--highlighted, .select2-container--bootstrap4 .select2-results__option--highlighted.select2-results__option[aria-selected="true"] {
    background-color: $secondary;
}

.dropdown-item:hover, .dropdown-item:focus {
    background-color: $secondary;
    color: $white;
}

.select2-selection.is-invalid {
    border-color: $red;
    padding-right: 2.25rem !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    padding-right: 3rem !important;
    background-position: right 1.5rem center;
}

.select2-selection.is-invalid:focus {
    border-color: $red;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
}

.select2-selection.is-valid {
    border-color: #28a745;
    padding-right: 2.25rem !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    border-color: #28a745;
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
    padding-right: 3rem !important;
    background-position: right 1.5rem center;
}

.body-login {
    background-color: #e9ecef !important;
}

