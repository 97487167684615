//
// Mixins: Toasts
//

// Toast Variant
@mixin toast-variant($name, $color) {
  #{if(&, '&.bg-#{$name}','.bg-#{$name}')} {
    background-color: rgba($color, .9) !important;
    @if (color-yiq($color) == $yiq-text-light) {

      .close {
        color: color-yiq($color);
        text-shadow: 0 1px 0 $black;
      }
    }

    .toast-header {
      background-color: rgba($color, .85);
      color: color-yiq($color);
    }
  }
}

